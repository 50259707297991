<template>
  <div>
    <v-overlay :value="overlay" absolute>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card flat>
      <v-toolbar flat color="white">
        <v-toolbar-title>
          <v-icon class="mb-1">mdi-file-cabinet</v-icon>
          INVENTARIO DE GUIAS
        </v-toolbar-title>

        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="awb"
          @click:append="cargarLista()"
          @keyup.enter="cargarLista()"
          clearable
          small
          append-icon="mdi-magnify"
          label="AWB"
          single-line
          class="mt-5 mx-1"
        >
        </v-text-field>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="desde"
          @click:append="cargarLista()"
          @keyup.enter="cargarLista()"
          @change="cargarLista()"
          small
          type="date"
          append-icon="mdi-magnify"
          label="Desde"
          single-line
          class="mt-5 mx-1"
        >
        </v-text-field>
        <v-text-field
          v-model="hasta"
          @click:append="cargarLista()"
          small
          @keyup.enter="cargarLista()"
          type="date"
          clearable
          @change="cargarLista()"
          append-icon="mdi-magnify"
          label="Hasta"
          single-line
          class="mt-5 mx-1"
        >
        </v-text-field>
        <v-spacer></v-spacer>
        <v-autocomplete
          v-model="aerolinea_id"
          small
          item-text="AEROLINEA_NOMBRE"
          item-value="AEROLINEA_ID"
          :items="listaAerolineas"
          @change="cargarLista()"
          clearable
          label="Aerolínea"
          class="mt-5"
        >
        </v-autocomplete>
        <v-spacer></v-spacer>
        <v-autocomplete
          v-model="estado_id"
          small
          item-text="nombre"
          item-value="id"
          :items="estados"
          @change="cargarLista()"
          clearable
          label="Estado"
          class="mt-5"
        >
        </v-autocomplete>
      </v-toolbar>
      <v-toolbar flat class="" color="#f9f9f9">
        <v-btn small color="info" class="mx-2" @click="abrirDialogReserva"
          ><v-icon left>mdi-clock-outline</v-icon>Reservar</v-btn
        >
        <v-btn small color="info" class="mx-2" @click="abrirDialogDevolver"
          ><v-icon left>mdi-file-rotate-left-outline</v-icon>Devolver</v-btn
        >
        <v-btn small color="info" class="mx-2"
          ><v-icon left>mdi-cancel</v-icon>Anular</v-btn
        >
        <v-btn small color="info" class="mx-2" @click="abrirDialogEstados"
          ><v-icon left>mdi-history</v-icon>Ver Historial</v-btn
        >

        <v-btn small color="info" class="mx-2" @click="abrirDialogGenerarGuias"
          ><v-icon left>mdi-file-cog-outline</v-icon>Generar lote de
          guias</v-btn
        >
      </v-toolbar>
      <v-data-table
        id="listaInventario"
        :headers="headerInv"
        :options.sync="options"
        :items="listaInv"
        :itemsPerPage="itemsPerPage"
        :server-items-length="totalItems"
        class="elevation-1"
        show-select
        :single-select="true"
        v-model="seleccion"
        item-key="AWB_NUM"
        hide-default-footer
      >
        <template v-slot:item.AWB_NUM="{ item }">
          <v-btn
            v-if="guiaConDatos(item) == true"
            small
            text
            target="_blank"
            :to="`coordinacion_guia_aerea/${item.AWB_NUM}`"
            ><v-icon left>mdi-eye</v-icon>{{ item.AWB_NUM }}</v-btn
          >
          <span v-else> {{ item.AWB_NUM }}</span>
        </template>
        <template v-slot:item.opcion="{ item }">
          <v-btn
            v-if="item.ESTADO == 'CERRADA' && item.LOG_MENSAJES.length > 0"
            icon
            color="green"
            target="_blank"
            @click="cargarLogExportar(item)"
          >
            <v-icon color="info">mdi-information-outline</v-icon>
          </v-btn>
          <v-btn
            v-if="item.ESTADO == 'CERRADA' && item.MENSAJES > 0"
            icon
            color="green"
            target="_blank"
            @click="mensajesGuia(item.AWB)"
          >
            <v-icon color="primary">mdi-email</v-icon>
          </v-btn>
        </template>

        <template v-slot:footer>
          <v-pagination
            class="mt-10"
            v-model="currentPage"
            :length="pageCount"
            @input="handlePageChange"
            total-visible="10"
          ></v-pagination>
        </template>
      </v-data-table>
    </v-card>
    <!-- DIALOG GENERAR LOTE GUIAS -->
    <v-dialog
      v-model="dialogGenerarGuias"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar height="50" dark color="blue">
          <v-toolbar-title
            ><v-icon left>mdi-cogs</v-icon>Generar Guias</v-toolbar-title
          >
          <v-divider class="mx-2" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn class="mr-2" icon small @click="dialogGenerarGuias = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <GenerarLoteForm></GenerarLoteForm>
      </v-card>
    </v-dialog>
    <!-- fin dialog generar lote guias -->
    <!-- DIALOG RESERVAR GUIA -->
    <v-dialog
      v-model="dialogReservarGuia"
      max-width="800"
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar height="45" dark color="blue">
          <v-toolbar-title>Reservar Guia</v-toolbar-title>

          <v-divider class="mx-2" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn text small @click="dialogReservarGuia = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-list round>
            <v-list-item-group
              v-for="coordab in coordAbiertas"
              v-bind:key="coordab.HCOORD_ID"
              color="primary"
            >
              <v-list-item
                target="_blank"
                :to="`/reservar_guia_aerea/${coordab.HCOORD_ID}/${guiaSeleccionada.AWB_NUM}/${guiaSeleccionada.AEROLINEA_ID}`"
              >
                {{ coordab.HCOORD_FECHA }}</v-list-item
              >

              <!-- <v-list-item
                v-if="coordab.ESPACIO_AL == false"
                @click="alertaEspacio"
              >
                {{ coordab.HCOORD_FECHA }}</v-list-item
              > -->
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- fin dialogo reservar -->
    <!-- DIALOG DEVOLVER ANULAR GUIA -->
    <v-dialog
      v-model="dialogDevolverGuia"
      max-width="800"
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar height="45" dark color="blue" flat>
          <v-toolbar-title>Devolver Guia</v-toolbar-title>
          <v-divider class="mx-2" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn text small @click="dialogDevolverGuia = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-form ref="formDevolverGuia">
          <v-card-text>
            <v-col col="12" class="my-0 py-0">
              <v-text-field
                small
                class="my-0"
                label="AWB"
                v-model="datosDevol.AWB"
              ></v-text-field>
            </v-col>
            <v-col col="12" class="my-0 py-0">
              <v-text-field
                small
                class="my-0"
                label="Num ADR"
                v-model="datosDevol.ADR"
                :rules="requiredRule"
              ></v-text-field>
            </v-col>
            <v-col col="12" class="my-0 py-0">
              <v-text-field
                class="my-0"
                label="Fecha"
                v-model="datosDevol.FECHA"
                type="date"
                :rules="requiredRule"
              ></v-text-field>
            </v-col>
            <v-col col="12" class="my-0 py-0">
              <v-text-field
                class="my-0"
                label="Observaciones"
                v-model="datosDevol.OBS"
              ></v-text-field>
            </v-col>
          </v-card-text>
          <v-card-actions>
            <v-btn
              small
              color="green"
              class="mx-4 mb-4"
              dark
              @click="devolverGuia"
              ><v-icon small left>mdi-content-save</v-icon>Aceptar</v-btn
            >
            <v-btn
              small
              text
              color="blue"
              class="mx-4 mb-4"
              dark
              @click="dialogDevolverGuia = false"
              ><v-icon small left>mdi-cancel</v-icon>Cancelar</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- fin dialogo reservar -->
    <!-- DIALOG DEVOLVER ANULAR GUIA -->
    <v-dialog
      v-model="dialogLogEstados"
      max-width="800"
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar height="45" dark color="blue" flat>
          <v-toolbar-title>Estados Guia</v-toolbar-title>
          <v-divider class="mx-2" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn text small @click="dialogLogEstados = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-data-table
            :headers="headerLogEstados"
            :items="logEestadosGuia"
            :itemsPerPage="itemsPerPage"
            class="elevation-1"
            hide-default-footer
          ></v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- fin dialog log estados-->
  </div>
</template>
<style>
#listaInventario td,
.v-input--selection-controls .v-radio > .v-label {
  font-size: 0.65rem !important;
}
</style>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import GenerarLoteForm from "./GenerarLoteGuias.vue";
export default {
  name: "InventarioGuiasComponent",
  components: { GenerarLoteForm },
  data: () => ({
    listaInv: [],
    headerInv: [
      { text: "AWB NUM.", value: "AWB_NUM" },
      { text: "AEROLINEA", value: "AEROLINEA_NOMBRE" },
      { text: "CONSIGNATARIO", value: "CLIENTE_NOMBRE" },
      { text: "ESTADO", value: "ESTADO_GUIA" },
      { text: "FECHA DE VUELO", value: "FECHA" },
      { text: "ANULADA", value: "ANULADA" },
      { text: "DEVUELTA", value: "DEVUELTA" },
      { text: "DESTINO", value: "DESTINO" },
      { text: "ADDR#", value: "ADR_NUM" },
    ],
    itemsPerPage: 50,
    totalItems: 0,
    currentPage: 1,
    pageCount: 0,
    fecha: [],
    desde: "",
    hasta: "",
    estados: [
      { id: "SIN APROBAR", nombre: "SIN APROBAR" },
      { id: "DISPONIBLE", nombre: "DISPONIBLE" },
      { id: "ABIERTA", nombre: "ABIERTA" },
      { id: "RESERVADA", nombre: "RESERVADA" },
      { id: "DESPACHADA", nombre: "DESPACHADA" },
      { id: "DEVUELTA", nombre: "DEVUELTA" },
      { id: "ANULADA", nombre: "ANULADA" },
    ],
    listaAerolineas: [],
    coordAbiertas: [],
    datosDevol: {
      IAWB_ID: "",
      AWB: "",
      ADR: "",
      FECHA: "",
      OBS: "",
    },
    headerLogEstados: [
      { text: "AWB NUM.", value: "AWB_NUM" },
      { text: "USUARIO", value: "USUARIO_NOMBRE" },
      { text: "FECHA", value: "FECHA" },
      { text: "ESTADO", value: "ESTADO" },
    ],
    aerolinea_id: "",
    cnee_nombre: "",
    estado_id: "",
    awb: "",
    show: false,
    overlay: false,
    seleccion: [],
    buscar: "",
    options: {},
    dialogGenerarGuias: false,
    dialogReservarGuia: false,
    dialogDevolverGuia: false,
    dialogLogEstados: false,
    logEestadosGuia: [],
    requiredRule: [(v) => !!v || "El campo es requerido"],
  }),
  computed: {
    ...mapState("master", ["loadingBtn"]),
    guiaSeleccionada() {
      if (this.seleccion.length > 0) {
        return this.seleccion[0];
      }
      return "";
    },
  },

  methods: {
    ...mapActions("master", ["requestApi", "alertNotification"]),
    ...mapMutations("master", [
      "setUrl",
      "setOverlay",
      "setMenu",
      "setTitleToolbar",
      "setLoadingTable",
    ]),
    ...mapActions("master", ["requestApi", "alertNotification"]),
    cargarDatos() {
      this.setLoadingTable(true);
      this.setUrl("api/datos-inventario-guias");
      this.requestApi({
        method: "GET",
        data: {},
      })
        .then((res) => {
          this.listaAerolineas = res.data.aerolineas;
          this.coordAbiertas = res.data.hcoord_activa;
          this.setLoadingTable(false);
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    cargarLista() {
      this.setLoadingTable(true);
      this.setUrl("api/inventario-guias");
      this.requestApi({
        method: "GET",
        data: {
          pageCount: this.pageCount,
          itemsPerPage: this.itemsPerPage,
          page: this.currentPage,
          sortBy: this.options.sortBy[0],
          sortDesc: this.options.sortDesc[0],
          aerolinea_id: this.aerolinea_id,
          cnee_nombre: this.cnee_nombre,
          desde: this.desde,
          hasta: this.hasta,
          estado_id: this.estado_id,
          awb: this.awb,
        },
      })
        .then((res) => {
          this.listaInv = res.data.lista;
          this.pageCount = res.data.pageCount;
          this.totalItems = res.data.totalItems;
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    cargarLogEstados() {
      this.setLoadingTable(true);
      this.setUrl("api/log-estados-guia");
      this.requestApi({
        method: "GET",
        data: {
          awb: this.guiaSeleccionada.AWB_NUM,
        },
      })
        .then((res) => {
          this.logEestadosGuia = res.data;
          //this.itemsPerPage = 100;
          console.log(res.data);
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    abrirDialogGenerarGuias() {
      this.dialogGenerarGuias = true;
    },
    abrirDialogDevolver() {
      if (this.seleccion.length == 0) {
        this.alertNotification({
          param: {
            html: "Seleccione una guía",
            toast: false,
            title: "Devolver guía",
            icon: "info",
            timer: false,
          },
        });
        return false;
      }

      if (this.guiaSeleccionada.ESTADO_GUIA.trim() != "DEVUELTA") {
        //console.log(this.guiaSeleccionada.AEROLINEA_ID);
        this.datosDevol.AWB = this.guiaSeleccionada.AWB_NUM;
        this.datosDevol.IAWB_ID = this.guiaSeleccionada.IAWB_ID;
        console.log(this.datosDevol);
        this.dialogDevolverGuia = true;
      } else {
        this.alertNotification({
          param: {
            html: "la guia no se puede devolver.  ",
            toast: false,
            title: "Devolver guía",
            icon: "info",
            timer: false,
          },
        });
      }
    },
    abrirDialogEstados() {
      if (this.seleccion.length == 0) {
        this.alertNotification({
          param: {
            html: "Seleccione una guía",
            toast: false,
            title: "Reserva de guias",
            icon: "info",
            timer: false,
          },
        });
        return false;
      }
      this.dialogLogEstados = true;

      this.cargarLogEstados();
    },
    abrirDialogReserva() {
      if (this.seleccion.length == 0) {
        this.alertNotification({
          param: {
            html: "Seleccione una guía",
            toast: false,
            title: "Reserva de guias",
            icon: "info",
            timer: false,
          },
        });
        return false;
      }

      if (this.guiaSeleccionada.ESTADO_GUIA.trim() == "DISPONIBLE") {
        //console.log(this.guiaSeleccionada.AEROLINEA_ID);
        this.setLoadingTable(true);
        this.setUrl("api/hcoord-espacio-al");
        this.requestApi({
          method: "GET",
          data: {
            aerolineaId: this.guiaSeleccionada.AEROLINEA_ID,
          },
        })
          .then((res) => {
            //this.hc logEestadosGuia = res.data;
            this.coordAbiertas = res.data;
            this.dialogReservarGuia = true;
          })
          .catch(() => {})
          .then(() => {
            this.setLoadingTable(false);
          });
      } else {
        this.alertNotification({
          param: {
            html: "la guia no se puede reservar. Seleccione una guía DISPONIBLE",
            toast: false,
            title: "Reserva de guias",
            icon: "info",
            timer: false,
          },
        });
      }
    },
    handlePageChange() {
      this.cargarLista();
    },
    devolverGuia() {
      //this.setLoadingTable(true);
      if (!this.$refs.formDevolverGuia.validate()) {
        return false;
      }

      this.setUrl("api/devolver-guia-lote");
      this.requestApi({
        method: "POST",
        data: {
          iawb_id: this.datosDevol.IAWB_ID,
          awb: this.datosDevol.AWB,
          fecha: this.datosDevol.FECHA,
          adr: this.datosDevol.ADR,
          obs: this.datosDevol.OBS,
        },
      })
        .then((res) => {
          this.cargarLista();
          this.dialogDevolverGuia = false;
          this.logEestadosGuia = res.data;
          this.seleccion = [];
        })
        .catch(() => {})
        .then(() => {
          //this.setLoadingTable(false);
        });
    },
    guiaConDatos(item) {
      if (item.ABIERTA == 1 || item.RESERVADA == 1 || item.CERRADA == 1) {
        return true;
      }
      return false;
    },
    alertaEspacio() {
      this.alertNotification({
        param: {
          html: "la guia no se puede reservar. No hay espacio en esta coordinación para la aerolinea",
          toast: false,
          title: "Reserva de guias",
          icon: "info",
          timer: false,
        },
      });
    },
  },
  mounted() {
    document.title = "Inventario de guias";
    this.setTitleToolbar("Inventario de guias");
    this.cargarDatos();
    //this.cargarLista();
  },
};
</script>
<style>
.theme--light.v-data-table tbody tr.v-data-table__selected {
  background: #6eaff8;
}
</style>
